////
/// @group _custom-config.scss
////



// ==================================
//  Дополнительные значения
//  по текущему проекту
// ==================================

/// @type Size
$border-radius: .25em;

// Colors

$green-color: #15a853;
$red-color: #ed1c24;
$white-color: #ffffff;
$dark-text: #333333;
$light-text: #6c6c6c;


//Text

$bold: 900;
$regular: 500;
$light: 100;

._color-green {
	color: $green-color;
}

._color-red {
	color: $red-color;
}

._color-dark {
	color: $dark-text;
}

._color-light {
	color: $light-text;
}

._color-white {
	color: $white-color;
}

._light-weight {
	font-weight: $light;
}

._regular-weight {
	font-weight: $regular;
}

._bold-weight {
	font-weight: $bold;
}

._line-height-20 {
	line-height: 20px;
}

._line-height-25 {
	line-height: 25px;
}

._line-height-30 {
	line-height: 30px;
}

._line-height-40 {
	line-height: 40px;
}

._text-center {
	text-align: center;
}

._uppercase {
	text-transform: uppercase;
}

._underline  {
	text-decoration: underline;
}

._no-underline {
	text-decoration: none;
	transition: 0.3s;

	&:hover {
		text-decoration: underline;
	}
}

//Font sizing

._font-size-50 {
	font-size: 3.125rem;
}

._font-size-40 {
	font-size: 2.5rem;
}

._font-size-22 {
	font-size: 1.375rem;
}

._font-size-20 {
	font-size: 1.25rem;
}

._font-size-14 {
	font-size: 0.875rem;
}

._font-size-12 {
	font-size: 0.75rem;
}

//Other

._green-underline {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 100%;
		bottom: -5px;
		background-color: $green-color;
		transition: 0.5s;
		left: 0;
	}

	&:hover {
		&:after {
			width: 50%;
		}
	}
}

._pd-20 {
	padding: 20px;
}

._pd-10 {
	padding: 10px;
}

._max-w-1200 {

	padding: 0 20px;

	@media (min-width: 1200px) {
		padding: 0;
		margin: auto;
		max-width: 1200px;
	}
}

._max-w-600 {
	padding: 0 10px;

	&--auto {
		max-width: 600px;
		margin: auto;
	}

	@media (min-width: 600px) {
		padding: 0;
		max-width: 600px;
	}
}

._pos-rel {
	position: relative;
}

._hide {
	display: none;
}

._invisible {
	visibility: hidden;
}
