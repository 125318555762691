._color-green {
  color: #15a853;
}

._color-red {
  color: #ed1c24;
}

._color-dark {
  color: #333333;
}

._color-light {
  color: #6c6c6c;
}

._color-white {
  color: #ffffff;
}

._light-weight {
  font-weight: 100;
}

._regular-weight {
  font-weight: 500;
}

._bold-weight {
  font-weight: 900;
}

._line-height-20 {
  line-height: 20px;
}

._line-height-25 {
  line-height: 25px;
}

._line-height-30 {
  line-height: 30px;
}

._line-height-40 {
  line-height: 40px;
}

._text-center {
  text-align: center;
}

._uppercase {
  text-transform: uppercase;
}

._underline {
  text-decoration: underline;
}

._no-underline {
  text-decoration: none;
  transition: 0.3s;
}

._no-underline:hover {
  text-decoration: underline;
}

._font-size-50 {
  font-size: 3.125rem;
}

._font-size-40 {
  font-size: 2.5rem;
}

._font-size-22 {
  font-size: 1.375rem;
}

._font-size-20 {
  font-size: 1.25rem;
}

._font-size-14 {
  font-size: 0.875rem;
}

._font-size-12 {
  font-size: 0.75rem;
}

._green-underline {
  position: relative;
}

._green-underline:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -5px;
  background-color: #15a853;
  transition: 0.5s;
  left: 0;
}

._green-underline:hover:after {
  width: 50%;
}

._pd-20 {
  padding: 20px;
}

._pd-10 {
  padding: 10px;
}

._max-w-1200 {
  padding: 0 20px;
}

@media (min-width: 1200px) {
  ._max-w-1200 {
    padding: 0;
    margin: auto;
    max-width: 1200px;
  }
}

._max-w-600 {
  padding: 0 10px;
}

._max-w-600--auto {
  max-width: 600px;
  margin: auto;
}

@media (min-width: 600px) {
  ._max-w-600 {
    padding: 0;
    max-width: 600px;
  }
}

._pos-rel {
  position: relative;
}

._hide {
  display: none;
}

._invisible {
  visibility: hidden;
}

@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1440px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1439px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: "GothicCentury";
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: inherit;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  background-color: #fff;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

textarea {
  resize: none;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.error-view {
  width: 100%;
  height: 93%;
  text-align: center;
  background: url("pic/hands-with-plant.png") left center no-repeat #ffffff;
}

.error-view__block {
  text-align: left;
  width: 90%;
  max-width: 580px;
  margin: 1rem auto;
}

.error-view__code {
  font-weight: bold;
  font-size: 8rem;
  line-height: .92;
  text-align: right;
  color: #15a853;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 1280px) {
  .error-view__code {
    font-size: 12rem;
  }
}

.error-view__msg {
  margin-top: -2em;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 1em 2em;
  border-radius: 10px;
  line-height: 1.4;
  background: url("pic/5element--form.png") center no-repeat #f0f0f0;
}

.error-view__msg .green-fill-btn {
  width: 49%;
  line-height: 40px;
  display: inline-block;
  margin-top: 60px;
  box-sizing: border-box;
  border: 1px solid #15a853;
}

.error-view__msg .green-fill-btn:hover {
  background-color: transparent;
  color: #15a853;
}

@media only screen and (min-width: 1280px) {
  .error-view__msg {
    margin-top: -3rem;
    padding: 2rem;
    line-height: 1.4;
  }
}

.error-view__msg ul, .error-view__msg ol, .error-view__msg p {
  margin: .65em 0;
}

.error-view__msg ul, .error-view__msg ol {
  padding-left: 1.5rem;
}

.error-view__msg small {
  display: inline;
}

.error-view__msg a {
  text-decoration: none;
}

.error__title {
  font-size: 2.5rem;
  margin-bottom: 0;
}

.error__first {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .error__first:before, .error__first:after {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    background: url("pic/leaf.svg") no-repeat;
  }
  .error__first:after {
    transform: rotate(387deg);
    right: -13px;
    top: 0;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .error__first:after {
    right: -8px;
    top: 90px;
  }
}

@media only screen and (min-width: 768px) {
  .error__first:before {
    transform: rotate(270deg);
    left: -12px;
    top: 38px;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .error__first:before {
    left: 30px;
  }
}

.error__third {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .error__third:before, .error__third:after {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    background: url("pic/leaf.svg") no-repeat;
  }
  .error__third:after {
    transform: rotate(355deg);
    right: -9px;
    bottom: 87px;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .error__third:after {
    transform: rotate(280deg);
    right: 67px;
  }
}

@media only screen and (min-width: 768px) {
  .error__third:before {
    transform: rotate(380deg);
    right: -12px;
    top: 0;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .error__third:before {
    right: -8px;
  }
}
