////
/// @group error.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	// @include normalize__grouping-content;
	// @include normalize__text-level-semantics;
	// @include normalize__embedded-content;
	// @include normalize__forms;
	// @include normalize__interactive;
	// @include normalize__scripting;
	// @include normalize__hidden;

	// @include normalize__text-level-semantics__psevdo-classes;
	// @include normalize__forms__psevdo-classes;
	// @include normalize__addons;



// Import Partials
// ================================
	@import '_partials/base';



// Custom css
// ================================
	.error-view {
		width: 100%;
		height: 93%;
		text-align: center;
		background: url("pic/hands-with-plant.png") left center no-repeat #ffffff;

		&__block {
			text-align: left;
			width: 90%;
			max-width: 580px;
			margin: 1rem auto;
		}

		&__code {
			font-weight: bold;
			font-size: 8rem;
			//margin-right: 2rem;
			line-height: .92;
			text-align: right;
			color: $green-color;
			display: flex;
			justify-content: flex-end;

			@include media( $lg ) {
				font-size: 12rem;
			}
		}
	}

	.error-view__msg {
		margin-top: -2em;
		box-shadow: 0 3px 10px rgba(#000, .2);
		padding: 1em 2em;
		border-radius: 10px;
		line-height: 1.4;
		background: url("pic/5element--form.png") center no-repeat #f0f0f0;

		.green-fill-btn {
			width: 49%;
			line-height: 40px;
			display: inline-block;
			margin-top: 60px;
			box-sizing: border-box;
			border: 1px solid $green-color;

			&:hover {
				background-color: transparent;
				color: $green-color;
			}
		}

		@include media( $lg ) {
			margin-top: -3rem;
			padding: 2rem;
			line-height: 1.4;
		}

		ul, ol, p {
			margin: .65em 0;
		}

		ul, ol {
			padding-left: 1.5rem;
		}

		small {
			display: inline;
		}

		a {
			text-decoration: none;
		}
	}


	.error__title {
		font-size: 2.5rem;
		margin-bottom: 0;
	}

	.error__first {
		position: relative;

		@include media($md, min) {
			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 25px;
				height: 25px;
				background: url("pic/leaf.svg") no-repeat;
			}

			&:after {
				transform: rotate(387deg);
				right: -13px;
				top: 0;

				@include media($lg, min) {
					right: -8px;
					top: 90px;
				}
			}

			&:before {
				transform: rotate(270deg);
				left: -12px;
				top: 38px;

				@include media($lg, min) {
					left: 30px;
				}
			}
		}
	}

	.error__third {
		position: relative;

		@include media($md, min) {
			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 25px;
				height: 25px;
				background: url("pic/leaf.svg") no-repeat;


			}
			&:after {
				transform: rotate(355deg);
				right: -9px;
				bottom: 87px;

				@include media($lg, min) {
					transform: rotate(280deg);
					right: 67px;
				}
			}

			&:before {
				transform: rotate(380deg);
				right: -12px;
				top: 0;

				@include media($lg, min) {
					right: -8px;
				}
			}
		}
	}